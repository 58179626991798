import React from "react";
import Signup from "./Signup";
import { Container, Navbar, Button } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { isMobile } from "react-device-detect";
import { auth } from "../firebase";

import { Routes, Route, useNavigate } from "react-router-dom";
import AccountDashboard from "./AccountDashboard";
import Login from "./Login";
import CreateTestEvent from "./Events/CreateEvent";
import OnBoarding from "./OnBoarding";
import PublicRoute from "./PublicRoute";
import PageRoute from "./PageRoute";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
import Posts from "./Posts";
import PostDetails from "./PostDetails";
import CountryDetails from "./CountryDetails";
import GroupDetails from "./GroupDetails";
import ExperienceEditor from "./ExperienceEditor";
import ExpDetails from "./ExpDetails";
import BizDetails from "./BizDetails";
import UserProfile from "./UserProfile";
import NameCard from "./NameCard";
import EventDetails from "./EventDetails";
import NotFound from "./NotFound";
import InteractionDetails from "./InteractionDetails";
import CreateEvent from "./CreateEvent";
import ArticleDetails from "./ArticleDetails";
import ArticleAnalytics from "./ArticleAnalytics";
import EventDashboard from "./EventDashboard";
import EventCheckIn from "./EventCheckin/EventCheckin";
import LiveEventDashboard from "./LiveEventDashboard";
import EventManagementDashboard from "./EventManagementDashboard";
import EventLuckyDrawDashboard from "./Events/EventLuckyDrawDashboard";
import EventTicketing from "./EventTicketing";
import CreateNewEvent from "./CreateNewEvent";
import DeleteAccount from "./Accounts/DeleteAccount";

import OrgnizationProfile from "./OrgnizationProfile";
import DataAnalytics from "./DataAnalytics";
import GroupInfoEdit from "./GroupInfo";
import GroupContactInfo from "./GroupContactInfo";
import CreateEditLinks from "./CreateEditLinks";
import GroupNameInfoEdit from "./GroupNameInfoEdit";
import Experiences from "./Exps";
import InteractionViewer from "./InteractionViewer";
import InteractionEditor from "./InteractionEditor";
import PostEditor from "./PostEditor";
import GroupQuotes from "./GroupQuotes";
import Marketplace from "./Marketplace";
import AllEvents from "./AllEvents";
import SendGroupEmails from "./SendGroupEmails";
import ReceiptPage from "./Supports/ReceiptPage";
import Extensions from "./Supports/Extensions";

import AllResources from "./AllResources";
import Pricing from "./WelcomeGroup/Pricing";
import Plan from "./WelcomeGroup/Plan";
import MessageCenter from "./WelcomeGroup/MessageCenter";
import AdminAndRoles from "./Admins/AdminAndRoles";

import Home from "./WelcomeGroup/Home";
import AdminOrderDetails from "./Admins/AdminOrderDetails";
import AdminPageViews from "./Admins/AdminPageView";
import AdminAllOrganizations from "./Admins/AdminAllOrganizations";
import CheckInSystem from "./EventCheckin/CheckInSystem";
import AdminEmailCampaign from "./Admins/AdminEmailCampaign";

const logoImg =
  "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/eventgo.JPG";

function App() {
  function onClickBtn() {
    window.open("https://evtgo.com");
  }

  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "60%";

  if (isMobile) {
    return (
      <div>
        <Navbar
          hidden={
            window.location.href.includes("/article/") ||
            window.location.href.includes("/post/")
              ? true
              : false
          }
          bg="black"
          variant="dark"
          expand="lg"
          sticky="top"
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <b>EVENTGO</b>
            </Navbar.Brand>

            <Button
              style={{
                backgroundColor: "#F6AC19",
                borderColor: "#000000",
                color: "#000000",
                height: "30px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "15px",
              }}
              onClick={onClickBtn}
            >
              EVTGO WEB
            </Button>
          </Container>
        </Navbar>
        <AuthProvider>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
            </Route>

            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<AccountDashboard />} />
              <Route path="/admin-email-campaign" element={<AdminEmailCampaign/>} />
              <Route path="/checkin-all" element={<CheckInSystem />} />
              <Route path="/admin-order-details/:orderId" element={<AdminOrderDetails />} />
              <Route path="/onboarding" element={<OnBoarding />} />
              <Route path="/update-profile" element={<UpdateProfile />} />
              <Route path="/update-group" element={<GroupInfoEdit />} />
              <Route path="/admin-page-views" element={<AdminPageViews />} />
              <Route path="/admin-all-org" element={<AdminAllOrganizations />} />
              <Route path="/admin-data-center" element={<AdminAllOrganizations />} />
              <Route
                path="/update-group-info"
                element={<GroupNameInfoEdit />}
              />
              <Route path="/data-analytic" element={<DataAnalytics />} />
              <Route
                path="/article-analytics/:id"
                element={<ArticleAnalytics />}
              />
              <Route path="/event-dashboard/:id" element={<EventDashboard />} />
              <Route path="/event-checkin/:id" element={<EventCheckIn />} />
              <Route path="/event-ticket/:id" element={<EventTicketing />} />
              <Route
                path="/live-event-dashboard/:id"
                element={<LiveEventDashboard />}
              />
              <Route
                path="/live-lucky-draw-dashboard/:id/:drawId"
                element={<EventLuckyDrawDashboard />}
              />
              <Route path="/resources" element={<AllResources />} />
              <Route path="/posts" element={<Posts />} />
              
              <Route path="/posts/:topic" element={<Posts />} />
              <Route path="/create-event" element={<CreateEvent />} />
              <Route path="/create-live-event" element={<CreateEvent />} />
              <Route path="/modify-event/:id" element={<CreateEvent />} />
              <Route
                path="/manage-event/:id"
                element={<EventManagementDashboard />}
              />
              <Route
                path="/generate-receipt/:id/:ticketId"
                element={<ReceiptPage />}
              />
              <Route path="/create-post" element={<PostEditor />} />
              <Route path="/create-exp" element={<ExperienceEditor />} />
              <Route path="/create-exp/:id" element={<ExperienceEditor />} />
              <Route path="/create-edit-links" element={<CreateEditLinks />} />
              <Route path="/experiences" element={<Experiences />} />
              <Route path="/group-quotes" element={<GroupQuotes />} />
              <Route
                path="/view-interaction/:id"
                element={<InteractionViewer />}
              />
              <Route
                path="/create-interaction"
                element={<InteractionEditor />}
              />
              <Route
                path="/create-interaction/:id"
                element={<InteractionEditor />}
              />
              <Route
                path="/group-contact-info"
                element={<GroupContactInfo />}
              />
              <Route path="/change-plan" element={<Plan />} />
            </Route>

            <Route path="/404" element={<NotFound />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/view-admin-and-roles" element={<AdminAndRoles />} />
            <Route path="/create-test-event" element={<CreateTestEvent />} />
            <Route path="/exp/:id" element={<ExpDetails />} />
            <Route path="/article/:id" element={<ArticleDetails />} />
            <Route path="/event/:id" element={<EventDetails />} />
            <Route path="/interaction/:id" element={<InteractionDetails />} />
            <Route path="/biz/:id" element={<BizDetails />} />
            <Route path="/u/:id" element={<UserProfile />} />
            <Route path="/org/:id" element={<OrgnizationProfile />} />
            <Route path="/:id" element={<NameCard />} />
            <Route path="/user/:id" element={<NameCard />} />
            <Route path="/post/:id" element={<PostDetails />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/:id" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/country/:id" element={<CountryDetails />} />
            <Route path="/group/:id" element={<GroupDetails />} />
            <Route path="/realtor/:id" element={<OrgnizationProfile />} />
            <Route path="/restaurant/:id" element={<OrgnizationProfile />} />
            <Route path="/pricing" element={<Pricing />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </div>
    );
  } else {
    return (
      <div>
        <Navbar
          hidden={
            window.location.href.includes("/user/") ||
            window.location.href.includes("/live-event-dashboard/") ||
            window.location.href.includes("/live-lucky-draw-dashboard/")
              ? true
              : false
          }
          bg="black"
          variant="dark"
          expand="lg"
          sticky="top"
          style={{ height: "60px" }}
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <b>EVENTGO</b>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />

            <div>
              <Button
                style={{
                  color: "#ffffff",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
                hidden={auth.currentUser != null}
                onClick={() => {
                  if (auth.currentUser != null) {
                    navigate("/");
                  } else {
                    navigate("/");
                  }
                }}
              >
                Login
              </Button>
              <Button
                style={{
                  backgroundColor: "#F6AC19",
                  borderColor: "transparent",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "25px",
                }}
                hidden={auth.currentUser != null}
                onClick={() => {
                  if (auth.currentUser != null) {
                    navigate("/");
                  } else {
                    navigate("/signup");
                  }
                }}
              >
                New Account
              </Button>
            </div>
          </Container>
        </Navbar>

        <AuthProvider>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/explore-events" element={<AllEvents />} />
              <Route path="/pricing" element={<Pricing />} />
            </Route>

            <Route element={<PrivateRoute />}>
              <Route path="/view-admin-and-roles" element={<AdminAndRoles />} />
              <Route path="/admin-email-campaign" element={<AdminEmailCampaign/>} />
              <Route path="/checkin-all" element={<CheckInSystem />} />
              <Route path="/admin-order-details/:orderId" element={<AdminOrderDetails/>} />
              <Route path="/dashboard" element={<AccountDashboard />} />
              <Route path="/onboarding" element={<OnBoarding />} />
              <Route path="/update-profile" element={<UpdateProfile />} />
              <Route path="/update-group" element={<GroupInfoEdit />} />
              <Route path="/admin-page-views" element={<AdminPageViews />} />
              <Route path="/admin-all-org" element={<AdminAllOrganizations />} />
              <Route path="/admin-data-center" element={<AdminAllOrganizations />} />
              <Route
                path="/update-group-info"
                element={<GroupNameInfoEdit />}
              />
              <Route path="/data-analytic" element={<DataAnalytics />} />
              <Route
                path="/article-analytics/:id"
                element={<ArticleAnalytics />}
              />
              <Route path="/event-checkin/:id" element={<EventCheckIn />} />
              <Route path="/event-ticket/:id" element={<EventTicketing />} />
              <Route path="/event-dashboard/:id" element={<EventDashboard />} />
              <Route
                path="/manage-event/:id"
                element={<EventManagementDashboard />}
              />
              <Route
                path="/generate-receipt/:id/:ticketId"
                element={<ReceiptPage />}
              />
              <Route
                path="/live-event-dashboard/:id"
                element={<LiveEventDashboard />}
              />
              <Route
                path="/live-lucky-draw-dashboard/:id/:drawId"
                element={<EventLuckyDrawDashboard />}
              />
              <Route path="/posts" element={<Posts />} />
              <Route path="/posts/:topic" element={<Posts />} />
              <Route path="/resources" element={<AllResources />} />
              <Route path="/create-post" element={<PostEditor />} />
              <Route path="/create-event" element={<CreateEvent />} />
              <Route path="/create-test-event" element={<CreateTestEvent />} />
              <Route path="/create-new-event" element={<CreateNewEvent />} />
              <Route path="/create-live-event" element={<CreateEvent />} />
              <Route path="/modify-event/:id" element={<CreateEvent />} />
              <Route path="/create-exp" element={<ExperienceEditor />} />
              <Route path="/create-exp/:id" element={<ExperienceEditor />} />
              <Route path="/create-edit-links" element={<CreateEditLinks />} />
              <Route path="/experiences" element={<Experiences />} />
              <Route
                path="/view-interaction/:id"
                element={<InteractionViewer />}
              />
              <Route
                path="/create-interaction"
                element={<InteractionEditor />}
              />
              <Route
                path="/create-interaction/:id"
                element={<InteractionEditor />}
              />
              <Route path="/group-quotes" element={<GroupQuotes />} />
              <Route
                path="/group-contact-info"
                element={<GroupContactInfo />}
              />
              <Route path="/send-emails/:id" element={<SendGroupEmails />} />
              <Route path="/change-plan" element={<Plan />} />
              <Route path="/message-center" element={<MessageCenter />} />
            </Route>

            <Route element={<PageRoute />}>
              <Route path="/user/:id" element={<NameCard />} />
            </Route>

            <Route path="/home" element={<Home />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/event/:id" element={<EventDetails />} />
            <Route path="/interaction/:id" element={<InteractionDetails />} />
            <Route path="/exp/:id" element={<ExpDetails />} />
            <Route path="/biz/:id" element={<BizDetails />} />
            <Route path="/u/:id" element={<UserProfile />} />
            <Route path="/org/:id" element={<OrgnizationProfile />} />
            <Route path="/:id" element={<NameCard />} />
            <Route path="/post/:id" element={<PostDetails />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/:id" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/country/:id" element={<CountryDetails />} />
            <Route path="/group/:id" element={<GroupDetails />} />
            <Route path="/realtor/:id" element={<OrgnizationProfile />} />
            <Route path="/restaurant/:id" element={<OrgnizationProfile />} />
            <Route path="/pricing" element={<Pricing />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </div>
    );
  }
}

export default App;
