import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container, ListGroup } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useAuth } from "../../contexts/AuthContext";
import { auth, fires } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import firebase from "firebase";

export default function AdminEmailCampaign() {
  const maxW = isMobile ? "100%" : "90%";
  const [adminData, setAdminData] = useState({
      name: '',
      email: '',
      roles: []
  })
  const [userEmails, setUserEmails] = useState([])
  function loadUserEmails(){
    fires
    .collection("users")
    .orderBy("timestamp",'desc')
    .get()
    .then((snapshot)=>{
      let emails = []
      snapshot.docs.forEach((doc) => {
        const docData = doc.data()
        const docId = doc.id
        if (docData.email != null && docData.email != ""){
          if (!emails.includes(docData.email)){
            emails.push({email: docData.email, id: docId})
          }
        }
      })
      setUserEmails(emails)
    })
  }

  useEffect(() => {
      fires
      .collection('admin')
      .doc(auth.currentUser.uid)
      .get()
      .then((snapshot)=>{
          if (snapshot.exists){
            setAdminData({...snapshot.data()})
            loadUserEmails()
          }
          else{
            window.location.href = "/"
          }
      })
  },[])

  return (
    <>
      <Helmet>
        <title>View Email Campaign</title>
        <meta property="og:title" content="View Admins" />
        <meta
          property="og:description"
          content="View Admins"
        />
        <meta property="og:url" content={"https://evtgo.com/"} />
        <meta
          property="og:site_name"
          content="View Admins"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: maxW, marginTop: "25px", marginBottom: "25px" }}>
          <Card className="border-0" style={{ backgroundColor: "transparent" }}>
            <Card.Body className="align-items-center justify-content-center">
              <div style={{ marginTop: "25px", marginBottom: "45px" }}>
                <Card.Title 
                    className="text-center" 
                    style={{fontSize: '25px', fontWeight: 'bold'}}>
                  View User Information
                </Card.Title>
              </div>
              <DataGrid 
        checkboxSelection 
        editMode="row"
        sx={{ overflowX: 'scroll', marginTop: '15px' }}
        rows={userEmails} 
        columns={[{ field: "id", headerName: "User ID", flex: 0.2 },{ field: "email", headerName: "Email", flex: 1 },]} />
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
